import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getScale } from '../helpers/getScale.js';
import { Meter } from '../components/meter.js';
import { Solar } from '../components/solar.js';
import { Battery } from '../components/battery.js';
import { Transformer } from '../components/transformer.js';
import { MiniTransformer } from '../components/miniTransformer.js';
import { HorizontalConnector } from '../components/horizontalConnector.js';
import { VerticalConnector } from '../components/verticalConnector.js';
import { Connector } from '../components/connector.js';
import { BusDispenser } from '../components/busDispenser.js';
import { ElectricBus } from '../components/electricBus.js';
import { PowerGrid } from '../components/powerGrid.js';
import { Label } from '../components/label.js';
import { Irradiance } from '../components/irradiance.js';
import { TruckCharger } from '../components/truckCharger.js';
import { Cooler } from '../components/cooler.js';
import { Server } from '../components/serverRack.js';
import { PDU } from '../components/pdu.js';
import { GPU } from '../components/gpu.js';
import { Selector } from '../components/selector.js';

const componentMap = {
  Meter: Meter,
  Solar: Solar,
  Battery: Battery,
  Transformer: Transformer,
  MiniTransformer: MiniTransformer,
  BusDispenser: BusDispenser,
  ElectricBus: ElectricBus,
  PowerGrid: PowerGrid,
  HorizontalConnector: HorizontalConnector,
  VerticalConnector: VerticalConnector,
  Connector: Connector,
  Label: Label,
  Irradiance: Irradiance,
  TruckCharger: TruckCharger,
  Cooler: Cooler,
  Server: Server,
  PDU: PDU,
  GPU: GPU,
  Selector: Selector
};

export const SiteView = ({
  online,
  dataSource,
  jsonLayout,
  contentSizeState,
  containerSize,
  viewProps,
  isTabletOrMobile
}) => {
  const [content, setContent] = useState('Loading...');
  const [scale, setScale] = useState(1.1);
  // eslint-disable-next-line no-unused-vars
  const [contentSize, setContentSize] = contentSizeState;
  const [layoutSize, setLayoutSize] = useState({ width: 0, height: 0 });
  const [views, setViews] = viewProps.viewsState;
  const [selectedUlids, setSelectedUlids] = viewProps.selectedUlidsState;
  const viewMap = viewProps.viewMap;

  useEffect(() => {
    const newScale = getScale(layoutSize, containerSize);
    setScale(newScale);
    setContentSize({
      width: layoutSize.width * newScale,
      height: layoutSize.height * newScale
    });
  }, [layoutSize, containerSize]);

  // https://www.pluralsight.com/guides/how-to-render-a-component-dynamically-based-on-a-json-config
  const buildContent = (jsonLayout) => {
    const filteredLayout = jsonLayout.layout.filter(
      (c) => c.component in componentMap
    );
    return filteredLayout.map((c, i) =>
      React.createElement(componentMap[c.component], {
        ...c.props,
        // eslint-disable-next-line react/no-array-index-key
        key: i,
        dataSource: dataSource,
        viewProps: viewProps,
        timezone: jsonLayout.timezone,
        isTabletOrMobile: isTabletOrMobile
      })
    );
  };

  const getLayoutSize = (layout) => {
    const gridCellSize = 40;
    let maxX = 0;
    let maxY = 0;
    layout.forEach((c) => {
      if (c.props.xaxis > maxX) maxX = c.props.xaxis;
      if (c.props.yaxis > maxY) maxY = c.props.yaxis;
    });

    return {
      width: (maxX + 4) * gridCellSize + 10,
      height: (maxY + 4) * gridCellSize
    };
  };

  // eslint-disable-next-line no-unused-vars
  const DEFAULT_READING = 'kw_reading';

  useEffect(() => {
    if (Object.keys(jsonLayout).length !== 0) {
      const _content = buildContent(jsonLayout);
      setLayoutSize(getLayoutSize(_content));
      setContent(_content);
    }
  }, [jsonLayout, selectedUlids, views]);

  const onClick = (e) => {
    setViews([viewMap.site]);
    setSelectedUlids([]);
    e.stopPropagation();
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={(e) => onClick(e)} style={{ transform: `scale(${scale})` }}>
      <div
        className={`site-layout ${!online ? 'site-offline' : ''}`}
        style={{
          width: `${layoutSize.width}px`,
          height: `${layoutSize.height}px`
        }}
      >
        {content}
      </div>
    </div>
  );
};

SiteView.propTypes = {
  online: PropTypes.bool,
  dataSource: PropTypes.object,
  jsonLayout: PropTypes.object,
  contentSizeState: PropTypes.array,
  containerSize: PropTypes.object,
  viewProps: PropTypes.object,
  isTabletOrMobile: PropTypes.bool
};
