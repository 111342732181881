/* eslint-disable @shopify/jsx-no-hardcoded-content */
/* eslint-disable @shopify/jsx-no-complex-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { View } from './view.js';
import { SiteView } from './siteView.js';
import { BatteryView } from './batteryView.js';
import { MeterView } from './meterView.js';
import { ChartView } from './chartView.js';
import { useInterval } from '../helpers/useInterval.js';
import {
  meterReadingsEventName,
  publish,
  weatherReadingsEventName
} from '../helpers/events.js';

const viewMap = {
  site: SiteView,
  battery: BatteryView,
  meter: MeterView,
  chart: ChartView
};

export const ViewLayout = ({
  dataSource,
  selectedLayout,
  onlineState,
  isTabletOrMobile,
  viewStateMenu
}) => {
  const [online, setOnline] = onlineState;
  const viewsState = useState([SiteView]);
  const [views, setViews] = viewsState;
  const [viewState, setViewState] = viewStateMenu;
  // eslint-disable-next-line no-unused-vars
  const [selectedMeter, setSelectedMeter] = useState('');

  // viewMode could be used for regulator config in addition to ESS config
  const viewModeState = useState('Monitor');
  const [ulidMap, setUlidMap] = useState([]);
  const [ulids, setUlids] = useState([]);
  const [weatherUlids, setWeatherUlids] = useState([]);
  const selectedUlidsState = useState([]);
  const selectedReadingsState = useState(['kva_reading']);
  const setSelectedUlids = selectedUlidsState[1];
  const [jsonLayout, setJsonLayout] = useState({});
  const [viewSizes, setViewSizes] = useState([
    { width: 1.0, height: 1.0 },
    { width: 0, height: 1.0 },
    { width: 1.0, height: 0 },
    { width: 0, height: 0 }
  ]);
  const containerSize = {
    width: window.innerWidth * 0.95,
    height: window.innerHeight * 0.81
  };

  useEffect(() => {
    if (viewState === 'meter') {
      setViews([viewMap.site, viewMap.meter, viewMap.chart]);
    } else {
      setViews([viewMap.site]);
    }
  }, [viewState]);

  useInterval(() => {
    if (ulids.length !== 0) {
      dataSource.fetch(ulids, (response) => {
        if (response.status === 'success') {
          setOnline(true);
          publish(meterReadingsEventName, response.data);
        } else {
          console.log(response.data);
          setOnline(false);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.interval);

  useInterval(() => {
    if (weatherUlids.length !== 0) {
      dataSource.fetchWeather(weatherUlids, (response) => {
        if (response.status === 'success') {
          publish(weatherReadingsEventName, response.data);
        } else {
          console.log(response.data);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, dataSource.weatherInterval);

  useEffect(() => {
    if (weatherUlids.length !== 0) {
      dataSource.fetchWeather(weatherUlids, (response) => {
        if (response.status === 'success') {
          publish(weatherReadingsEventName, response.data);
        } else {
          console.log(response.data);
          if (response.status === 'logout') {
            dataSource?.auth?.setToken(null);
          }
        }
      });
    }
  }, [weatherUlids]);

  const layoutHandler = (_jsonLayout) => {
    setJsonLayout(_jsonLayout);
    const _ulids = _jsonLayout.layout
      .filter((c) => c.props.ulid !== undefined)
      .map((c) => c.props.ulid);
    setUlids(_ulids);

    const _weatherUlids = _jsonLayout.layout
      .filter((c) => c.component === 'Irradiance')
      .map((c) => c.props.ulid);
    setWeatherUlids(_weatherUlids);

    const _ulidMaps = _jsonLayout.layout
      .filter((c) => c.props.ulid !== undefined && c.props.name !== undefined)
      .map((c) => c.props);
    setUlidMap(_ulidMaps);
    setSelectedUlids([]);
  };

  useEffect(() => {
    setViews([viewMap.site]);
    setSelectedUlids([]);
    setUlidMap([]);
    dataSource.fetchLayout(selectedLayout, layoutHandler);
  }, [selectedLayout]);

  useEffect(() => {
    if (views.length <= 1) {
      setViewState('layout');
      setViewSizes([
        { width: 1.0, height: 1.0 },
        { width: 0, height: 1.0 },
        { width: 1.0, height: 0 },
        { width: 0, height: 0 }
      ]);
    } else if (views.length === 2) {
      setViewSizes([
        { width: 0.55, height: 1.0 },
        { width: 0.445, height: 1.0 },
        { width: 1.0, height: 0 },
        { width: 0, height: 0 }
      ]);
    } else if (views.length === 3) {
      setViewState('meter');
      setViewSizes([
        { width: 0.55, height: 0.5 },
        { width: 0.445, height: 0.5 },
        { width: 1.0, height: 0.5 },
        { width: 0, height: 0 }
      ]);
    }
  }, [views]);

  // maybe all 4 views need to be there all the
  // time for react-spring to work??
  const viewElements = views.map((view, index) => (
    <View
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      width={viewSizes[index].width}
      height={viewSizes[index].height}
      containerSize={containerSize}
    >
      {React.createElement(view, {
        dataSource: dataSource,
        selectedLayout: selectedLayout,
        jsonLayout: jsonLayout,
        ulids: ulids,
        online: online,
        containerSize: containerSize,
        viewProps: {
          viewMap: viewMap,
          viewsState: viewsState,
          viewModeState: viewModeState,
          selectedUlidsState: selectedUlidsState,
          selectedReadingsState: selectedReadingsState,
          siteId: selectedLayout.split('.')[0],
          timezone: jsonLayout.timezone
            ? jsonLayout.timezone
            : 'America/Los_Angeles'
        },
        isTabletOrMobile: isTabletOrMobile
      })}
    </View>
  ));
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', height: '83vh' }}>
      {isTabletOrMobile ? (
        <div />
      ) : (
        <div
          style={{
            position: 'absolute',
            height: '5vh',
            marginTop: '2vh',
            marginLeft: '87vw',
            marginBottom: '-10vh',
            zIndex: 10
          }}
        >
          <Form.Select
            onChange={(e) => {
              setSelectedMeter(e.target.value);
              setSelectedUlids(
                ulidMap.filter((c) => c.name === e.target.value)[0].ulid
              );
              setSelectedUlids([
                ulidMap.filter((c) => c.name === e.target.value)[0].ulid
              ]);
            }}
            value={
              ulidMap.filter((c) => c.ulid === selectedUlidsState[0][0])[0] !=
              null
                ? ulidMap.filter((c) => c.ulid === selectedUlidsState[0][0])[0]
                    .name
                : ''
            }
          >
            <option value='' hidden>
              Select a Meter
            </option>
            {ulidMap
              .filter(function (value) {
                if (value.ulid != 0) {
                  return value;
                }
              })
              .map((value) => (
                <option key={value.ulid} value={value.name}>
                  {value.name}
                </option>
              ))}
          </Form.Select>
        </div>
      )}
      {viewElements}
    </div>
  );
};

ViewLayout.propTypes = {
  dataSource: PropTypes.object,
  selectedLayout: PropTypes.string,
  onlineState: PropTypes.array,
  isTabletOrMobile: PropTypes.bool,
  viewStateMenu: PropTypes.array
};
